
/*Stile Header*/
.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    color: white;
}

.logo h2 {
    margin: 0;
    font-size: 30px;
    color: #282c34;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.466);
    margin: 10px 0;
    font-weight: bold;
    letter-spacing: 2px;
}

.up {
    background-color: #282c34;
    color: white;
    margin-right: 10px;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
}

.up:hover {
    background-color: #DCDCDC;
    color: #282c34;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.774);
}

/*Stile error-message*/
.error-message {
    color: rgba(255, 0, 0, 0.493);
    margin-top: 20px;
}

/*Stile suggestions*/
.search-container {
    margin-bottom: 20px;
}

.suggestions {
    margin-top: 20px;
    max-width: 1200px;
    border: 1px solid #e9e9e9a8;
    background-color: rgba(255, 255, 255, 0.719);
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/*Stile tabelle*/
table {
    border-collapse: collapse;
    width: 158.84vh;
    margin: auto;
}

th,
td {
    border: 1px solid #e9e9e9a8;
    text-align: left;
    padding: 12px;
}

tr:nth-child(even) {
    background-color: #e9e9e9;
}

th {
    background-color: #777777;
    color: white;
}

td {
    background-color: #444444;
    color: white
}

th {
    position: sticky;
    top: 0;
    z-index: 1;
}

input[type="text"] {
    border: none;
    outline: none;
    width: 100%;
    background-color: #444444;
    color: white;
}

input[type="number"] {
    border: none;
    outline: none;
    width: 100%;
    background-color: #444444;
    color: white;
}

.link {
    border: none;
    background-color: #777777;
}