/*Button*/
/*Button back*/
.back {
    background-color: #282c34;
    color: white;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 2px;
    padding: 14px 28px;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
}

.back:hover {
    background-color: #DCDCDC;
    color: #282c34;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.774);
}

/*Button url e drag&drop*/
.buttons {
    display: flex;
    justify-content: center;
    align-items: start;
    margin-left: 20px;
    gap: 10px;
}

.drags {
    border: 1px solid #282c3446;
    background-color: #f1f1f1d8;
    color: #282c34;
    padding: 10px;
    margin-bottom: 30px;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
}

.url {
    border: 1px solid #282c3446;
    background-color: #f1f1f1d8;
    color: #282c34;
    padding: 10px;
    margin-bottom: 30px;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
}

.url:hover,
.drags:hover {
    background-color: #dcdcdcb2;
    transform: translateY(-2px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.603);
}

/*Button apply*/
.apply {
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    width: 95px;
    height: 32px;
    padding: 8px;
    border: none;
    background-color: #282c34;
    color: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
}

.apply:hover {
    background-color: #333333c9;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
    transform: translateY(-1px);
}

/*Button remove*/
.remove {
    border: 1px solid black;
    background-color: white;
    cursor: pointer;
    margin-left: 5px;
    border-radius: 5px;
}

.remove:hover {
    background-color: #d4192c;
    color: white;
    border-color: #d4192c;
}

/*End Button*/

.input {
    border: 2px dashed #cccccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    padding: 15px 20px;
}

.custom-file-upload p {
    margin: 5px 0;
}

.input.drag {
    width: calc(100% - 40px);
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ccc;
    border-radius: 5px;
}

.input.drag.active {
    background-color: rgba(0, 0, 0, 0.1);
}

.input input[type="file"] {
    display: none;
}

.selected-options {
    width: 10%;
}

.custom-file-upload {
    cursor: pointer;
}

ul {
    list-style-type: none;
}

textarea {
    width: calc(100% - 20px);
    border-radius: 4px;
    padding: 10px;
}

textarea.drag::placeholder {
    text-align: center;
}

/* Media Queries for 768px */
@media (max-width: 768px) {

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    .input {
        height: auto;
        width: 90%;
        margin: 20px auto;
    }

    p {
        width: 100%;
        height: 10px;
        margin-top: auto;
    }

    .file-preview {
        margin-top: 10px;
    }

    .opzioni {
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
    }
}