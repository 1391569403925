body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa;
    color: #333;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

#loginPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.login {
    height: 55px;
    width: 269px;
    border-radius: 5px;
    background-color: #1e1e1e;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.login:hover {
    background-color: #333333;
}

.icon1,
.icon2 {
    width: 41px;
    height: auto;
}


a {
    color: #ffffff;
    text-decoration: none;
    background-color: #d9d9d9;
    border-radius: 30px;
}

a:hover {
    background-color: #b0b0b0;
}


.top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin: auto;


}

.upload {
    width: 164px;
    height: 31px;
    border-radius: 30px;
    border: none;
    background-color: #D9D9D9;
    margin: 3%;
    cursor: pointer
}

.up {
    color: #1E1E1E;
    text-align: center;
    text-decoration: none;
    line-height: 28px;

}

.searchbar {
    margin: 20px auto;
    max-width: 1200px;
}

.search {
    width: 60%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.search:focus {
    border-color: #282c34;
    box-shadow: 0 0 5px rgba(29, 29, 29, 0.678);
}

.search::placeholder {
    color: #333;
    opacity: 1;
}


.expButton {
    height: 55px;
    width: 269px;
    border-radius: 5px;
    background-color: #1e1e1e;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.colonna {
    width: 32px;
}

.applyButton {
    height: 30px;
    width: 150px;
    margin-left: 4px;
    font-size: 19px;
    border-radius: 5px;
    background-color: #1E1E1E;
    color: #FFFFFF;
    border: none;
    margin-top: 4vh;
    margin-bottom: 4vh;
    cursor: pointer
}

header {
    display: flex;
    align-items: start;
    margin-left: 10px;
}

.opzioni {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 15px;

}

#option {
    border-radius: 5px;
    width: 200px;
    height: 30px;

}

.input {

    height: 40vh;
    width: 125vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    outline: dashed;
}

.buttonHeader {
    width: 158.84vh;
    background-color: #777777;
    color: white;
    height: 5vh;
    border: none;
    cursor: pointer;
}