body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: #333;
}

/*Login Page ed Icons*/
#loginPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
}

.icon1{
    margin: 10px; 
    padding: 10px; 
    background-color: #f4f4f4; 
    border-radius: 20%; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.486); 
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(min-width: 481px) and (max-width:761px){
    #loginPage{
        background-color: #f4f4f4;
    }
    .logo {
        width: 80%; 
        height: auto;
    }
    .icon1{
        width: fit-content;
    }
}

/*Button login */
.login {
    cursor: pointer;
    background-color: #000000dc; 
    color: white; 
    border: none; 
    font-size: 20px;
    border-radius: 5px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
}

.login:hover {
    background-color:	#DCDCDC;
    color: #282c34;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.774);
    transform: scale(1.1);
}